/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';

const REQUEST_TIMEOUT = 2 * 60 * 1000;
const DEV_BASE_URL = 'http://192.168.1.36:4409';
const PORD_BASE_URL = '/api/'; // https://www.haozhengming.cn:4409

export const BASE_URL =
  process.env.NODE_ENV === 'development' ? DEV_BASE_URL : PORD_BASE_URL;

axios.defaults.timeout = Number(REQUEST_TIMEOUT) || 60 * 1000;
axios.defaults.baseURL = BASE_URL;

//失败提示
function msag(status: string | number) {
  switch (status) {
    case 401:
      return '未授权，请登录';
    case 403:
      return '拒绝访问';
    case 404:
      return '请求地址出错';
    case 408:
      return '请求超时';
    case 500:
      return '服务器内部错误';
    case 501:
      return '服务未实现';
    case 502:
      return '网关错误';
    case 503:
      return '服务不可用';
    case 504:
      return '网关超时';
    case 505:
      return 'HTTP版本不受支持';
    default:
      return '出错了';
  }
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(`请求出错：「${msag(error)}」`, error);
    return error?.response ?? {};
  }
);

//统一接口处理，返回数据
interface IParams {
  url: string;
  method: 'get' | 'post' | 'put' | 'delete';
  params?: any;
  data?: any;
  headers?: any;
  isForm?: boolean;
  paramsSerializer?: (params: any) => string;
  responseType?: string;
  showSucMsg?: boolean;
  successMsg?: string;
  showErrMsg?: boolean;
}

const http = <T = undefined,>({
  url,
  method,
  params,
  data,
  headers,
  isForm = false,
  paramsSerializer,
  responseType = 'json',
  showSucMsg = false,
  successMsg = '操作成功',
  showErrMsg = true,
}: IParams): Promise<{
  success: boolean;
  data: NonNullable<T> extends never ? any : T;
}> => {
  return new Promise((resolve) => {
    const options: Record<string, any> = {
      url,
      method,
      params,
      paramsSerializer,
      data,
      responseType,
      headers: headers || {},
      timeout: REQUEST_TIMEOUT,
      withCredentials: true,
    };

    isForm && (options['content-type'] = 'application/x-www-form-urlencoded');
    isForm && (options.data = qs.stringify(options.data));

    axios(options).then((resp) => {
      if (!resp) {
        showErrMsg && toast('请求地址不存在 ⊙﹏⊙ !', { type: 'error' });
        return;
      }

      const resData = resp?.data ?? {};
      if (resData.success) {
        showSucMsg && toast(successMsg, { type: 'success' });
      } else if (resData.code === 401) {
        // /sign?authRedirect
        // const a = Router()
        // Router.push('/sign?authRedirect');
      } else {
        showErrMsg && toast(resData.message, { type: 'error' });
      }
      resolve({ data: resData.data, success: resData.success });
    });
  });
};

export default http;
