import http from '../utils/http';
import { IUserInfo } from '../hooks/useUserActions';

interface IUseData {
  userInfo: IUserInfo;
}

export const login = async (account: string, password: string) => {
  const { success, data } = await http<IUseData>({
    url: 'auth/login',
    method: 'post',
    data: { account, password },
  });
  return { success, data: data?.userInfo || {} };
};

export const register = async (account: string, password: string) => {
  const { success, data } = await http<IUseData>({
    url: 'user/register',
    method: 'post',
    data: { account, password },
  });
  return { success, data: data.userInfo };
};

/** 获取当前用户的最新信息 */
export const getLatestUserInfo = async () => {
  const { success, data } = await http<IUseData>({
    url: 'user/info/latest',
    method: 'get',
  });
  return { success, data: data.userInfo };
};

interface IRenewVip {
  level: string;
  time: string;
  price: number;
  orderName: string;
  payChannel: string;
}
/** 会员订阅/续费 */
export const renewVip = async (data: IRenewVip) => {
  return await http({ url: 'vip/renew', method: 'post', data });
};

/** 获取会员套餐列表 */
export const getPriceList = async () => {
  return await http({ url: 'vip/price/list', method: 'get' });
};

/** 获取支付平台的URL */
export const getPaymentUrl = async (params: {
  orderCode: string;
  UA?: 'PC' | 'Phone';
}) => {
  return await http<{ url: string }>({
    url: 'payment/url',
    method: 'get',
    params,
  });
};

/** 获取订单是否以完成 */
export const getOrderIsCompleted = async (orderCode: string) => {
  return await http<{ url: string }>({
    url: 'order/getOrderIsCompleted',
    method: 'get',
    params: { orderCode },
  });
};

export const uploadPrevive = async (formData: FormData) => {
  const { success, data } = await http({
    url: 'upload/preview',
    method: 'post',
    data: formData,
  });
  return { success, data };
};
