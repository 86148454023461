/* eslint-disable @typescript-eslint/no-explicit-any */
import { h } from 'preact';
import { useImperativeHandle, useState } from 'preact/hooks';
import { ForwardFn, forwardRef, lazy, Suspense } from 'preact/compat';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
const TextField = lazy(() => import('@mui/material/TextField'));
import { IProps } from 'ahooks/lib/useWhyDidYouUpdate';
import { login, register } from '../../../src/apis';
import { toast } from 'react-toastify';
import useUserActions from '../../hooks/useUserActions';

type ISignType = 'login' | 'register';

export interface ISignHandle {
  handleClose: () => void;
  handleOpen: (type: ISignType) => void;
}

const Sign: ForwardFn<IProps, ISignHandle> = (props, ref) => {
  const { loginAction } = useUserActions();
  const [isOpen, setIsOpen] = useState(false);
  const [signType, setSignType] = useState<ISignType>('login');
  const [loginData, setLoginData] = useState({ account: '', password: '' });
  const [registerData, setRegisterData] = useState({
    account: '',
    password: '',
    confirmPassword: '',
  });

  const emptyErrTips = (v: any, str: string) => {
    if (!v) {
      toast(str, { type: 'warning' });
      throw new Error(str);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setLoginData({ account: '', password: '' });
    setRegisterData({ account: '', password: '', confirmPassword: '' });
  };

  const handleOpen = (type: ISignType) => {
    setIsOpen(true);
    setSignType(type);
  };

  const handleLogin = async () => {
    emptyErrTips(loginData.account, '账号不能为空。');
    emptyErrTips(loginData.password, '密码不能为空。');
    if (loginData.account.length < 6) {
      return toast('账号不能小于6位数!', { type: 'warning' });
    } else if (loginData.account.length > 10) {
      return toast('账号不能大于10位数!', { type: 'warning' });
    }
    if (loginData.password.length < 6) {
      return toast('密码不能小于6位数!', { type: 'warning' });
    } else if (loginData.password.length > 12) {
      return toast('密码不能大于12位数!', { type: 'warning' });
    }
    const { success, data } = await login(
      loginData.account,
      loginData.password
    );
    if (success) {
      loginAction(data);
      handleClose();
      toast('登录成功。', { type: 'success' });
    }
  };

  const handleRegister = async () => {
    emptyErrTips(registerData.account, '账号不能为空。');
    emptyErrTips(registerData.password, '密码不能为空。');
    if (registerData.password !== registerData.confirmPassword) {
      return toast('密码和确认密码必须相等!', { type: 'warning' });
    }
    const { success, data } = await register(
      registerData.account,
      registerData.password
    );
    if (success) {
      loginAction(data);
      handleClose();
      toast('注册成功，已为您您自动登录。', { type: 'success' });
    }
  };

  useImperativeHandle(ref, () => ({
    handleClose,
    handleOpen,
  }));

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      TransitionProps={{ style: { alignItems: 'start', marginTop: '15vh' } }}
    >
      <Suspense fallback={<div />}>
        {signType === 'login' ? (
          <Box sx={{ padding: '10px 20px' }}>
            <DialogTitle sx={{ padding: '10px', textAlign: 'center' }}>
              登录
            </DialogTitle>
            <DialogContent>
              <TextField
                label="账号"
                type="text"
                fullWidth
                variant="standard"
                size="small"
                value={loginData.account}
                onChange={(e: any) => {
                  const value: string = (e?.target as any)?.value || '';
                  setLoginData((data) => ({ ...data, account: value }));
                }}
              />
              <TextField
                label="密码"
                type="password"
                fullWidth
                variant="standard"
                size="small"
                value={loginData.password}
                onChange={(e: any) => {
                  const value: string = (e?.target as any)?.value || '';
                  setLoginData((data) => ({ ...data, password: value }));
                }}
              />
            </DialogContent>
            <DialogActions sx={{ paddingBottom: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button onClick={handleLogin} fullWidth variant="contained">
                    登录
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={() => setSignType('register')}
                    color="secondary"
                    fullWidth
                    variant="contained"
                  >
                    没有账号？点击注册
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Box>
        ) : (
          <Box sx={{ padding: '10px 20px' }}>
            <DialogTitle sx={{ padding: '10px', textAlign: 'center' }}>
              注册
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                label="账号"
                type="text"
                fullWidth
                variant="standard"
                size="small"
                value={registerData.account}
                onChange={(e: any) => {
                  const value: string = (e?.target as any)?.value || '';
                  setRegisterData((data) => ({ ...data, account: value }));
                }}
              />
              <TextField
                label="密码"
                type="password"
                fullWidth
                variant="standard"
                size="small"
                value={registerData.password}
                onChange={(e: any) => {
                  const value: string = (e?.target as any)?.value || '';
                  setRegisterData((data) => ({ ...data, password: value }));
                }}
              />
              <TextField
                label="确认密码"
                type="password"
                fullWidth
                variant="standard"
                size="small"
                value={registerData.confirmPassword}
                onChange={(e: any) => {
                  const value: string = (e?.target as any)?.value || '';
                  setRegisterData((data) => ({
                    ...data,
                    confirmPassword: value,
                  }));
                }}
              />
            </DialogContent>
            <DialogActions sx={{ paddingBottom: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={handleRegister}
                    fullWidth
                    variant="contained"
                  >
                    注册
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={() => setSignType('login')}
                    color="secondary"
                    fullWidth
                    variant="contained"
                  >
                    已有账号？点击登录
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Box>
        )}
      </Suspense>
    </Dialog>
  );
};

export default forwardRef(Sign);
