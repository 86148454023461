import { useCallback, useEffect, useState } from 'preact/hooks';
import jsCookie from 'js-cookie';
import { useDebounceFn } from 'ahooks';
import { getLatestUserInfo as _getLatestUserInfo } from '../apis';
import useCommonState from './useCommonState';

export interface IUserInfo {
  account: string;
  expirationTime: Date;
  renewTime: Date;
  vipLevel: '0' | '1';
  vipName: '免费会员' | 'VIP会员';
}

const useUserActions = (isGetLatest = false) => {
  const {
    commonState: { userInfo },
    dispatchCommonState,
  } = useCommonState();

  const [__userInfo, setUserInfo] = useState<null | IUserInfo>(
    userInfo || null
  );

  const loginAction = (_userInfo: IUserInfo) => {
    setUserInfo(_userInfo);
  };

  const logoutAction = useCallback(() => {
    setUserInfo(null);
    dispatchCommonState?.({ type: 'userInfo', data: null });
    jsCookie.remove('auth-cookie');
  }, [setUserInfo, dispatchCommonState]);

  const __getLatestUserInfo = useCallback(async () => {
    if (!jsCookie.get('auth-cookie')) return;

    const { success, data } = await _getLatestUserInfo();
    if (success) {
      loginAction(data);
      return data;
    }
  }, []);

  const { run: getLatestUserInfo } = useDebounceFn(__getLatestUserInfo, {
    wait: 200,
  });

  useEffect(() => {
    dispatchCommonState?.({ type: 'userInfo', data: __userInfo ?? null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [__userInfo]);

  useEffect(() => {
    if (isGetLatest && !__userInfo) {
      getLatestUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [__userInfo]);

  return {
    userInfo,
    isLogin: !!userInfo,
    isVip: userInfo?.vipLevel === '1',
    loginAction,
    logoutAction,
    getLatestUserInfo,
  };
};

export default useUserActions;
