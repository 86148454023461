import { useLayoutEffect, useState } from 'preact/hooks';

const usePageType = () => {
  const [type, setType] = useState<'quit' | 'work' | 'practice' | 'income'>(
    'quit'
  );

  useLayoutEffect(() => {
    const pathname = window.location.pathname;

    switch (pathname) {
      case '/quit':
        setType('quit');
        break;

      case '/work':
        setType('work');
        break;

      case '/practice':
        setType('practice');
        break;

      case '/income':
        setType('income');
        break;

      default:
        break;
    }
  }, [window.location.pathname]);

  return type;
};

export default usePageType;
