import { useContext, useReducer } from 'preact/hooks';
import { createContext } from 'preact';

import { IUserInfo } from './useUserActions';

interface IState {
  userInfo: null | IUserInfo;
}

type IAction<T> = {
  type: keyof T;
  data: T[keyof T];
};

export const initialState: IState = {
  userInfo: null,
};

export const commonReducer = (state: IState, action: IAction<IState>) => {
  switch (action.type) {
    case 'userInfo':
      return { ...state, userInfo: action.data };
    default:
      return { ...state };
  }
};

export const useCommonReducer = () => {
  const commonState = useReducer(commonReducer, initialState);

  return commonState;
};

export const commonContext = createContext<{
  commonState: IState;
  dispatchCommonState?:(action: IAction<IState>) => void;
}>({ commonState: initialState });

const useCommonState = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useContext(commonContext);
};

export default useCommonState;
